var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',{attrs:{"fluid":""}},[_c('Navbar',{attrs:{"navbarTitle":"Manage Vans","primaryBtnText":"Create","primaryBtnIcon":"mdi-plus"},on:{"primaryEvent":function($event){_vm.createVan = true;
        _vm.openDrawer();}},scopedSlots:_vm._u([{key:"afterTitle",fn:function(){return [_c('search-bar',{attrs:{"model":_vm.vanSearch,"label":"Search for Distributor/ Name/ Reg. No."},on:{"update:model":function($event){_vm.vanSearch=$event}}})]},proxy:true},(_vm.showVanList)?{key:"secondaryBtnSlot",fn:function(){return [_c('v-switch',{staticClass:"mr-4",attrs:{"label":"Inactive","color":"primary","hide-details":"","dense":""},on:{"change":_vm.switchVans},model:{value:(_vm.inactiveVans),callback:function ($$v) {_vm.inactiveVans=$$v},expression:"inactiveVans"}}),_c('ExcelUploadButton',{on:{"ClickEvent":function($event){return _vm.Redirect()}}})]},proxy:true}:null],null,true)}),_c('DataTable',{directives:[{name:"show",rawName:"v-show",value:(_vm.showVanList || _vm.VansLoading),expression:"showVanList || VansLoading"}],attrs:{"headers":_vm.tableHeaders,"items":_vm.vans,"pageCount":_vm.pages,"totalItems":_vm.totalVans,"page":_vm.page,"loading":_vm.VansLoading},on:{"changePage":_vm.changePage},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var props = ref.props;
        var headers = ref.headers;
return [(headers.value == 'action')?_c('span',[(_vm.inactiveVans)?_c('div',[_c('TextButton',{attrs:{"btnText":"Activate"},on:{"callAction":function($event){return _vm.activateVan(props)}}})],1):_c('div',[_c('v-btn',{staticClass:"suboverlineCapsReg primaryFABlue--text",staticStyle:{"border":"1px solid #d8d8d8","border-color":"#d8d8d8 !important"},attrs:{"color":"backgroundDark","height":"32","depressed":""},on:{"click":function($event){return _vm.editVan(props)}}},[_vm._v(" Edit ")]),_c('v-menu',{attrs:{"offset-y":"","close-on-scroll":true},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-4",attrs:{"color":"var(--blackMedium)"}},'v-icon',attrs,false),on),[_vm._v(" mdi-dots-horizontal ")])]}}],null,true)},[_c('v-list',{staticClass:"pa-0"},_vm._l((_vm.menuItems),function(menuItem,index){return _c('v-list-item',{key:index,on:{"click":function($event){return _vm.menuAction(props, index)}}},[_c('v-list-item-title',{class:menuItem.Color + "--text suboverlineCapsReg"},[_vm._v(_vm._s(menuItem.Text))])],1)}),1)],1)],1)]):_c('span',{class:props[headers.value] == '' ? "blackLow--text" : ""},[_vm._v(" "+_vm._s(props[headers.value] == "" ? headers.default : props[headers.value])+" ")])]}}])}),_c('Illustration',{directives:[{name:"show",rawName:"v-show",value:(!_vm.showVanList && !_vm.VansLoading),expression:"!showVanList && !VansLoading"}],attrs:{"description":_vm.description,"subdescription":_vm.subdescription,"illustrationImg":"Binoculars.png"},scopedSlots:_vm._u([{key:"illustrationActionSlot",fn:function(){return [_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.vanSearch.length < 1),expression:"vanSearch.length < 1"}]},[_c('v-divider',{staticClass:"mx-auto w-100px mt-8 highlightGrayBorder"}),_c('div',{staticClass:"text-center mt-8"},[_c('excel-upload-button'),_c('div',{staticClass:"blackMedium--text bodyLt mt-2"},[_vm._v(" Too Many Entries or have a ready excel ")])],1)],1)]},proxy:true}])}),_c('SideDrawer',{attrs:{"sidedrawerTitle":_vm.sidedrawerTitle,"sideDrawer":_vm.sideDrawer},on:{"update:sideDrawer":function($event){_vm.sideDrawer=$event},"update:side-drawer":function($event){_vm.sideDrawer=$event},"closeDrawer":_vm.closeSideDrawer},scopedSlots:_vm._u([{key:"bottomSlot",fn:function(){return [_c('v-btn',{staticClass:"overlineCapsReg mx-4",staticStyle:{"bottom":"16px"},style:(!_vm.valid
              ? "background-color: var(--highlightGray) !important; color: rgba(0, 0, 0, 0.6) !important;"
              : ""),attrs:{"color":"success","height":"32","block":"","depressed":"","absolute":"","disabled":!_vm.valid},domProps:{"textContent":_vm._s(
            _vm.valid
              ? _vm.createVan
                ? "Create"
                : "Save Changes"
              : "Fill Mandatory Details"
          )},on:{"click":_vm.createOrUpdateVan}})]},proxy:true}])},[_c('v-form',{ref:"form",staticClass:"mt-4 mb-4 ml-1-5",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-text-field',{attrs:{"placeholder":"Brief name for this van","rules":[function (v) { return !!v || 'Required'; }]},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"red--text"},[_vm._v("* ")]),_vm._v("Van Name ")]},proxy:true}]),model:{value:(_vm.editedItem.vanName),callback:function ($$v) {_vm.$set(_vm.editedItem, "vanName", $$v)},expression:"editedItem.vanName"}}),_c('v-text-field',{attrs:{"placeholder":"Registeration Number for reference purpose","rules":[function (v) { return !!v || 'Required'; }]},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"red--text"},[_vm._v("* ")]),_vm._v("Registeration Number ")]},proxy:true}]),model:{value:(_vm.editedItem.vanRegistrationNumber),callback:function ($$v) {_vm.$set(_vm.editedItem, "vanRegistrationNumber", $$v)},expression:"editedItem.vanRegistrationNumber"}}),_c('v-text-field',{attrs:{"placeholder":"Add Unique Van invoice prefix","rules":[
            function (v) { return !!v || 'Required'; },
            function (v) { return (v && v.length == 3) || 'Prefix should be of 3 characters'; },
            function (v) { return /^[a-zA-Z0-9\.]*$/.test(v) ||
              'Input must contain only alphabets or numbers'; } ]},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"red--text"},[_vm._v("* ")]),_vm._v("Van invoice prefix ")]},proxy:true}]),model:{value:(_vm.editedItem.vaninvoiceprefix),callback:function ($$v) {_vm.$set(_vm.editedItem, "vaninvoiceprefix", $$v)},expression:"editedItem.vaninvoiceprefix"}}),_c('v-text-field',{attrs:{"placeholder":"Chassis Number for reference purpose"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Chassis Number ")]},proxy:true}]),model:{value:(_vm.editedItem.chassisNumber),callback:function ($$v) {_vm.$set(_vm.editedItem, "chassisNumber", $$v)},expression:"editedItem.chassisNumber"}}),_c('v-text-field',{attrs:{"placeholder":"Van Capacity","rules":[function (v) { return !!v || 'Required'; }],"type":"number"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"red--text"},[_vm._v("* ")]),_vm._v("Van Capacity(in Kg) ")]},proxy:true}]),model:{value:(_vm.editedItem.vanCapacity),callback:function ($$v) {_vm.$set(_vm.editedItem, "vanCapacity", $$v)},expression:"editedItem.vanCapacity"}}),_c('v-autocomplete',{attrs:{"placeholder":"Type 3 characters to search","return-object":"","items":this.distributors,"item-text":"name","item-value":"id","rules":[function (v) { return !!v || 'Required'; }],"menu-props":{ bottom: true, offsetY: true, maxHeight: 200 }},on:{"change":function($event){return _vm.onDistributorChange(_vm.editedItem.distributorId.id)}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"red--text"},[_vm._v("* ")]),_vm._v("Assigned Distributor ")]},proxy:true},{key:"prepend-item",fn:function(){return [_c('ListItem',{attrs:{"title":_vm.distributors.length}})]},proxy:true},{key:"item",fn:function(ref){
          var item = ref.item;
return [_c('ListItemContent',{attrs:{"title":item.name,"subtitle":item.regionName}})]}}]),model:{value:(_vm.editedItem.distributorId),callback:function ($$v) {_vm.$set(_vm.editedItem, "distributorId", $$v)},expression:"editedItem.distributorId"}},[_c('v-icon',{attrs:{"slot":"append"},slot:"append"},[_vm._v(" mdi-magnify ")])],1)],1)],1)],1),_c('Snackbar',{attrs:{"message":_vm.message,"snackbar":_vm.snackbar,"timeout":5000,"color":_vm.snackbarColor},on:{"update:snackbar":function($event){_vm.snackbar=$event},"closeSnackbar":_vm.closeSnackbar}}),_c('ActionablePopup',{attrs:{"popupDialog":_vm.confirmationPopup,"title":_vm.confirmationPopupTitle,"subtitle":_vm.confirmationPopupSubtitle,"text":_vm.confirmationPopupText,"data":_vm.selectedVanId,"cancelBtnText":_vm.cancelBtnText,"confirmBtnText":_vm.confirmBtnText},on:{"close":function($event){_vm.confirmationPopup = false},"confirm":_vm.confirmAction}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }