





























































































































































































































































import { Component, Vue, Watch, Prop, Emit } from "vue-property-decorator";
import DropDown from "@/components/common/Inputs/DropDown.vue";
import Navbar from "@/components/common/Navbar.vue";
import ExcelUploadButton from "@/components/common/Buttons/ExcelUploadButton.vue";
import ListItem from "@/components/common/List/ListItem.vue";
import ListItemContent from "@/components/common/List/ListItemContent.vue";
import SideDrawer from "@/components/common/SideDrawer.vue";
import Illustration from "@/components/common/Illustration.vue";
import Snackbar from "@/components/common/Snackbar.vue";
import DistributorService from "@/services/DistributorService";
import VanSalesService from "@/services/VanSalesService";
import DetailsCard from "@/components/common/DetailsCard.vue";
import SearchBar from "@/components/common/SearchBar.vue";
import SearchableList from "@/components/common/SearchableList.vue";
import ActionablePopup from "@/components/common/Popup/ActionablePopup.vue";
import DataTable from "@/components/common/Tables/DataTable.vue";
import TextButton from "@/components/common/Buttons/TextButton.vue";
import { IDistributor } from "@/interfaces/positioncode";
import { IVan } from "@/interfaces/vansales";
import { ILink, ITableHeader } from "@/interfaces/common";
import { VLayout } from "vuetify/lib";
import CompanySettingService from "@/services/CompanySettingServics";
import { oldPortalUrl } from "@/envConfig";

@Component({
  components: {
    SearchBar,
    Illustration,
    Navbar,
    ExcelUploadButton,
    SideDrawer,
    Snackbar,
    DetailsCard,
    DropDown,
    SearchableList,
    ActionablePopup,
    DataTable,
    TextButton,
    ListItem,
    ListItemContent,
  },
})
export default class Create extends Vue {
  // for illustration
  private description: string = "No Vans Found";
  private subdescription: string = "Please create a Van to begin with";
  //for navbar
  private sidedrawerTitle: string = "Fill Van Details";
  //for side drawer
  private sideDrawer: boolean = false;
  private valid: boolean = false;
  private timeout!: NodeJS.Timeout;
  private message: string = "";
  private snackbar: boolean = false;
  private snackbarColor: string = "success";
  private showVanList: boolean = true;
  //flags for create or edit check
  private createVan: boolean = true;
  private menuItems: Array<ILink> = [];
  public distributors: Array<IDistributor> = [];
  private selectedVanId: number = 0;
  private vanSearch: string = "";
  private vans: Array<IVan> = [];
  private totalVans: number = 0;
  private pages: number = 0;
  private page: number = 1;
  private VansLoading: boolean = false;
  private selectedActionMenu: number = -1;
  private editedItem: IVan = {
    vanName: "",
    vanRegistrationNumber: "",
    chassisNumber: "",
    vanCapacity: 0,
    id: 0,
    distributorId: 0,
    distributorName: "",
    vaninvoiceprefix: "",
  };
  private defaultItem: IVan = {
    vanName: "",
    vanRegistrationNumber: "",
    chassisNumber: "",
    vanCapacity: 0,
    id: 0,
    distributorId: 0,
    distributorName: "",
    vaninvoiceprefix: "",
  };
  private confirmationPopup: boolean = false;
  private confirmationPopupTitle: string = "";
  private confirmationPopupSubtitle: string = "";
  private confirmationPopupText: string = "";
  private cancelBtnText: string = "";
  private confirmBtnText: string = "";
  private tableHeaders: Array<ITableHeader> = [
    {
      text: "Van Name",
      value: "vanName",
      sortable: false,
      default: "",
    },
    {
      text: "Invoice prefix",
      value: "vaninvoiceprefix",
      sortable: false,
      default: "",
    },
    {
      text: "Reg. Number",
      value: "vanRegistrationNumber",
      sortable: false,
      default: "",
    },
    {
      text: "Chassis Number",
      value: "chassisNumber",
      sortable: false,
      default: "",
    },
    {
      text: "Van Capacity",
      value: "vanCapacity",
      sortable: false,
      default: "VACANT",
    },
    {
      text: "Assigned Distributor",
      value: "distributorAssigned",
      sortable: false,
      default: "NA",
    },
    {
      text: "Actions",
      value: "action",
      sortable: false,
      default: "",
    },
  ];
  private inactiveVans: boolean = false;
  private creatingOrUpdatingVan: boolean = false;

  get formValidate(): Vue & { validate: () => boolean } {
    return this.$refs.form as Vue & { validate: () => boolean };
  }
  get formReset(): Vue & { reset: () => void } {
    return this.$refs.form as Vue & { reset: () => void };
  }
  get formResetValidation(): Vue & { resetValidation: () => void } {
    return this.$refs.form as Vue & { resetValidation: () => void };
  }

  @Watch("vanSearch")
  searchVan(newVal: string) {
    if (newVal && newVal.length > 2) {
      this.timeout = setTimeout(() => {
        this.inactiveVans
          ? this.getInactiveVans(newVal, 1)
          : this.getVans(newVal, 1);
      }, 1000);
    } else if (newVal.length == 0) {
      this.timeout = setTimeout(() => {
        this.inactiveVans ? this.getInactiveVans("", 1) : this.getVans("", 1);
      }, 1000);
    }
  }

  public showCommunicationError(err: any) {
    this.snackbarColor = "red";
    this.snackbar = true;
    this.message = window.navigator.onLine
      ? `Some Error occured`
      : `Cannot reach server at the moment`;
  }
  public onDistributorChange(id: number): void {
    this.editedItem.distributorId = id;
  }
  private createOrUpdateVan(): void {
    if (!this.creatingOrUpdatingVan)
      this.createVan ? this.createNewVan() : this.updateVan();
  }

  public menuAction(van: IVan, commandIndex: number) {
    this.selectedVanId = van.id;
    this.selectedActionMenu = commandIndex;
    switch (this.menuItems[commandIndex].Text) {
      case "DEACTIVATE":
        this.confirmationPopupTitle = "Confirm Deactivation";
        this.confirmationPopupSubtitle =
          "Are you sure you wish to deactivate the Van?";
        this.confirmationPopupText = "";
        this.cancelBtnText = "Cancel";
        this.confirmBtnText = "Confirm";
        this.confirmationPopup = true;
        break;
      default:
        console.log("Unhandled menuAction");
    }
  }
  public activateVan(van: IVan) {
    this.selectedVanId = van.id;
    this.confirmationPopupTitle = "Confirm Activation";
    this.confirmationPopupSubtitle = van.vanName + " van will be activated.";
    this.confirmationPopupText = "";
    this.cancelBtnText = "Keep Inactive";
    this.confirmBtnText = "Activate";
    this.confirmationPopup = true;
  }
  public editVan(value: IVan): void {
    this.createVan = false;
    this.openDrawer("");
    this.editedItem = Object.assign(
      {},
      {
        vanName: value.vanName,
        vanRegistrationNumber: value.vanRegistrationNumber,
        chassisNumber: value.chassisNumber,
        vanCapacity: value.vanCapacity,
        vaninvoiceprefix: value.vaninvoiceprefix,
        id: value.id,
        distributorId: value.distributorId,
        distributorName: value.distributorName,
      }
    );
  }
  public updateVan(): void {
    let value = Object.assign({}, this.editedItem);
    this.creatingOrUpdatingVan = true;
    VanSalesService.updateVan({
      vanName: value.vanName,
      vanRegistrationNumber: value.vanRegistrationNumber,
      chassisNumber: value.chassisNumber,
      vanCapacity: value.vanCapacity,
      id: value.id,
      vaninvoiceprefix: value.vaninvoiceprefix,
      distributorId: value.distributorId,
      distributorName: value.distributorName,
    })
      .then((response) => {
        this.snackbarColor = "success";
        this.snackbar = true;
        this.message = "Changes Saved Successfully";
        this.showVanList = true;
        this.creatingOrUpdatingVan = false;
        this.closeSideDrawer(false);
        this.clearForm();
        this.refreshVanList();
      })
      .catch((error) => {
        this.creatingOrUpdatingVan = false;
        if (error.response.status == 400) {
          this.snackbarColor = "red";
          this.snackbar = true;
          this.message = error.response.data;
        }
      });
  }
  public createNewVan(): void {
    let value = Object.assign({}, this.editedItem);
    this.creatingOrUpdatingVan = true;
    VanSalesService.createVan({
      vanName: value.vanName,
      vanRegistrationNumber: value.vanRegistrationNumber,
      vaninvoiceprefix: value.vaninvoiceprefix,
      chassisNumber: value.chassisNumber,
      vanCapacity: value.vanCapacity,
      id: value.id,
      distributorId: value.distributorId,
      distributorName: value.distributorName,
    })
      .then((response) => {
        this.snackbarColor = "success";
        this.snackbar = true;
        this.message = "Changes Saved Successfully";
        this.showVanList = true;
        this.creatingOrUpdatingVan = false;
        this.closeSideDrawer(false);
        this.clearForm();
        this.refreshVanList();
      })
      .catch((error) => {
        this.creatingOrUpdatingVan = false;
        if (error.response.status == 400) {
          this.snackbarColor = "red";
          this.snackbar = true;
          this.message = error.response.data;
        }
      });
  }
  public clearForm(): void {
    this.editedItem = Object.assign({}, this.defaultItem);
  }
  public async refreshVanList() {
    this.inactiveVans
      ? await this.getInactiveVans(this.vanSearch, 1)
      : await this.getVans(this.vanSearch, 1);
  }
  public openDrawer(value: string): void {
    this.sidedrawerTitle = this.createVan
      ? "Fill Van Details"
      : "Edit Van Details";
    this.clearForm();
    this.sideDrawer = true;
    this.formResetValidation.resetValidation();
  }
  public closeSideDrawer(value: boolean): void {
    this.hideWindowScrollBar(value);
    this.sideDrawer = value;
  }
  private getRefAsAny(refKey: string) {
    return this.$refs[refKey] as any;
  }

  public closeSnackbar(value: boolean): void {
    this.snackbar = value;
  }
  public Redirect() {

     window.open(
      `${oldPortalUrl}/BulkDataUpload/Upload/UpdateVanDistributorMapping`,
      "_blank"
    );
  }
  public async getVans(searchTerm: string, value: number): Promise<boolean> {
    this.VansLoading = true;
    return new Promise<boolean>((res) => {
      VanSalesService.GetVans(this.vanSearch, value)
        .then((response) => {
          this.vans = response.data.vans;

          this.pages = response.data.pages;
          this.totalVans = response.data.total;
          this.VansLoading = false;
          if (this.vans.length > 0) {
            this.showVanList = true;
          } else {
            this.showVanList = false;
            this.description =
              this.vanSearch.length == 0
                ? "No Vans Found"
                : "No matching result found";
            this.subdescription =
              this.vanSearch.length == 0
                ? "Please create a Van to begin with"
                : "Make sure you typed correctly or try finding in inactive Vans";
          }
          res(true);
        })
        .catch((error) => {
          this.showCommunicationError(error);
          console.log(error);
          this.VansLoading = false;
        });
    });
  }
  public changePage(value: number): void {
    this.page = value;
    this.inactiveVans
      ? this.getInactiveVans(this.vanSearch, value)
      : this.getVans(this.vanSearch, value);
  }

  public async getDistributors() {
    DistributorService.getDistributors([])
      .then((res) => {
        this.distributors = res.data;
      })
      .catch((e) => {
        console.log(e);
      });
  }

  public confirmAction(value: any): void {
    if (this.inactiveVans) {
      VanSalesService.activateVan(value)
        .then((res) => {
          this.snackbarColor = "success";
          this.message = "Changes Saved Successfully";
          this.actionablePopup();
          this.switchVans();
        })
        .catch((err: any) => {
          this.showCommunicationError(err);
          console.log(err);
        });
    } else {
      VanSalesService.deactivateVan(value)
        .then((res) => {
          this.snackbarColor = "red";
          this.message = "Van deactivated and moved to inactive list";
          this.actionablePopup();
          this.refreshVanList();
        })
        .catch((err) => {
          this.showCommunicationError(err);
          console.log(err);
        });
    }
  }
  public actionablePopup() {
    this.snackbar = true;
    this.confirmationPopup = false;
    this.showVanList = true;
  }
  private async GetMenuActions(): Promise<void> {
    CompanySettingService.getDistributorMapping().then((res) => {
      this.menuItems = [
        {
          Text: "DEACTIVATE",
          Color: "red",
        },
      ];
    });
  }

  public async getInactiveVans(
    searchTerm: string,
    page: number
  ): Promise<boolean> {
    this.VansLoading = true;
    return new Promise<boolean>((res) => {
      VanSalesService.getInactiveVans(searchTerm, page)
        .then((response) => {
          this.vans = response.data.vans;
          this.pages = response.data.pages;
          this.totalVans = response.data.total;
          this.VansLoading = false;
          res(true);
        })
        .catch((error) => {
          this.showCommunicationError(error);
          console.log(error);
          this.VansLoading = false;
        });
    });
  }
  public switchVans() {
    if (this.inactiveVans) {
      this.tableHeaders = [
        {
          text: "Van Name",
          value: "vanName",
          sortable: false,
          default: "",
        },
        {
          text: "Reg. Number",
          value: "vanRegistrationNumber",
          sortable: false,
          default: "",
        },
        {
          text: "Chassis Number",
          value: "chassisNumber",
          sortable: false,
          default: "",
        },
        {
          text: "Van Capacity",
          value: "vanCapacity",
          sortable: false,
          default: "VACANT",
        },
        {
          text: "Assigned Distributor",
          value: "distributorAssigned",
          sortable: false,
          default: "NA",
        },
        {
          text: "Actions",
          value: "action",
          sortable: false,
          default: "",
        },
      ];
      this.getInactiveVans(this.vanSearch, 1);
    } else {
      this.tableHeaders = [
        {
          text: "Van Name",
          value: "vanName",
          sortable: false,
          default: "",
        },
        {
          text: "Reg. Number",
          value: "vanRegistrationNumber",
          sortable: false,
          default: "",
        },
        {
          text: "Chassis Number",
          value: "chassisNumber",
          sortable: false,
          default: "",
        },
        {
          text: "Van Capacity",
          value: "vanCapacity",
          sortable: false,
          default: "VACANT",
        },
        {
          text: "Assigned Distributor",
          value: "distributorAssigned",
          sortable: false,
          default: "NA",
        },
        {
          text: "Actions",
          value: "action",
          sortable: false,
          default: "",
        },
      ];
      this.getVans(this.vanSearch, 1);
    }
  }
  async created() {
    this.VansLoading = true;
    this.getVans(this.vanSearch, 1);
    this.GetMenuActions().then((_) => _);
    this.getDistributors().then((_) => _);
  }

  private hideWindowScrollBar(hideIt: boolean) {
    window.document.documentElement.style.overflowY = hideIt
      ? "hidden"
      : "scroll";
  }
}
