import client from './../apiService';

class VanSalesService{
    async GetVans(searchTerm: string, page: number){
        const url = `VanSales/GetVans?searchTerm=${searchTerm}&page=${page}`;
        var result =  await client.get(url, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        debugger;
        return result;
    }

    deactivateVan(vanId: number){
        const url = `VanSales/DeactivateVan?vanId=${vanId}`;
        return client.get(url, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
    }

    activateVan(vanId: number){
        const url = `VanSales/ActivateVan?vanId=${vanId}`;
        return client.get(url, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
    }

    async getInactiveVans(searchTerm: string, page: number){
        const url = `VanSales/GetInactiveVans?searchTerm=${searchTerm}&page=${page}`;
        return await client.get(url, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }
    updateVan(van: object){
        const url = `VanSales/UpdateVan`;
        return client.post(url, van, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
    }
    createVan(van: object){
        const url = `VanSales/CreateVan`;
        return client.post(url, van, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
    }

}

export default new VanSalesService();